<template>
  <div class="carousel-container">
    <Carousel class="projectsCarousel" ref="projectsCarousel" :transition="animationSpeed" :wrap-around="true" @slide-start="updateCurrentProjectSlide" @mousedown="startClick" @mouseup="endClick">
      <Slide v-for="(project, index) in projects" :key="index">
        <div :class="`carousel__item project_item ${project.className}`" @click="handleClick">
          <div class="project-title-container">
            <h3 class="project-title">{{ project.title }}</h3>
            <Navigation />
          </div>
        </div>
      </Slide>
    </Carousel>

    <ProjectDetails :swipeDirection="swipeDirection" :activeProject="activeProject" :activeSlides="activeSlides" :animationSpeed="animationSpeed"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide, Pagination } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import ProjectDetails from '@/components/ProjectDetails.vue'
import projects from '@/misc/projects.js'
import projectSlides from '@/misc/projectSlides.js'
import { eventBus } from '@/event-bus.js';

export default defineComponent({
  props: {
      width: {
        type: Number
      }
    },
  name: 'WrapAround',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    ProjectDetails
  },
  data() {
    return {
      projects: projects["projects"],
      projectSlides: projectSlides["projectSlides"],
      clickStartX: 0,
      clickStartY: 0,
      clickEndX: 0,
      clickEndY: 0,
      prevProjectIdx: 0,
      activeProjectIdx: 0,
      swipeDirection: undefined,
      activeProject: undefined,
      activeSlides: undefined,
      animationSpeed: 500
      }
  },
  mounted() {
    this.activeProjectIdx = 0;
    this.activeProject = this.projects[this.activeProjectIdx];

    const _ = require('lodash');
    this.activeSlides = _.cloneDeep(this.projectSlides[this.activeProject["className"]]);
    // let the annals of history show that with this assignment "this.activeSlides = this.projectSlides[this.activeProject["className"]];" modifying activeSlides would sometimes MODYFY this.projectSlides! Took me an hour to figure this one out! Fuck you, whoever wrote this! Youre going to engineering hell!

    eventBus.on('nextProject', this.nextProject);
    eventBus.on('prevProject', this.prevProject);

    this.preloadThumbs()

    this.checkScreenWidth(); // Add this line
  },
  methods: {
    startClick(event) {
      this.clickStartX = event.clientX;
      this.clickStartY = event.clientY;
    },
    endClick(event) {
      this.clickEndX = event.clientX;
      this.clickEndY = event.clientY;
    },
    isClick() {
      const threshold = 5; // Adjust this value as needed
      const dx = Math.abs(this.clickEndX - this.clickStartX);
      const dy = Math.abs(this.clickEndY - this.clickStartY);
      return dx < threshold && dy < threshold;
    },
    updateCurrentProjectSlide(slide) {
      let newIdx = slide.slidingToIndex;

      const nProjects = this.projects.length;
      if (newIdx == nProjects) {
        newIdx = 0;
      } else if (newIdx == -1) {
        newIdx = nProjects - 1;
      }

      const maxIdx = nProjects - 1;
      // manage the wrap around edge cases 
      if (((newIdx * this.prevProjectIdx) == 0) && (newIdx + this.prevProjectIdx) == maxIdx)  {
        if (newIdx < this.prevProjectIdx) {
          this.swipeDirection = "right";
        } else {
          this.swipeDirection = "left";
        }
      } else {
          // simple logic from here
          if (newIdx > this.prevProjectIdx) {
            this.swipeDirection = "right";
          } else {
            this.swipeDirection = "left"
          }
      }
      
      this.prevProjectIdx = newIdx;
      this.activeProjectIdx = newIdx;
      this.activeProject = this.projects[this.activeProjectIdx];
      this.NewActiveSlides = this.projectSlides[this.activeProject["className"]]

      let delay = this.animationSpeed / (Object.keys(this.activeSlides).length - 1);
      let slideindex = 0;

      // update keys with a delay to avoid sudden shifts in the previous slide on rotation
      if (this.swipeDirection == "right") {
          for (let key in this.activeSlides) {
              setTimeout(() => {
                  if (this.NewActiveSlides[key]) {
                      this.activeSlides[key] = this.NewActiveSlides[key];
                  } else {
                      delete this.activeSlides[key];
                  }
              }, delay * slideindex);
              slideindex++;
          }
        } else if (this.swipeDirection == "left") {
          const keys = Object.keys(this.activeSlides);
          for (let i = keys.length - 1; i >= 0; i--) {
              let key = keys[i];
              setTimeout(() => {
                  if (this.NewActiveSlides[key]) {
                      this.activeSlides[key] = this.NewActiveSlides[key];
                  } else {
                      delete this.activeSlides[key];
                  }
              }, delay * (keys.length - 1 - i));
          }
      }

      //console.log('New activeSlides:', this.activeSlides);
    },
    nextProject() {
      this.swipeDirection = "right";
      this.$refs.projectsCarousel.next();
    },
    prevProject() {
      this.swipeDirection = "left";
      this.$refs.projectsCarousel.prev();
    },
    preloadThumbs() {
      for (const project in this.projectSlides) {
        for (const slide in this.projectSlides[project]) {
          const thumb = this.projectSlides[project][slide].thumb;
          const img = new Image();
          img.src = thumb;
        }
      }
    },
    checkScreenWidth() {
      console.log("checking width");
      if (this.screenWidth <= 480) {
        this.animationSpeed = 300;
      } else {
        this.animationSpeed = 500;
      }
    },
  },
  computed: {
    screenWidth() {
      return window.innerWidth;
    }
  },
  watch: {
    screenWidth(newWidth) {
      this.checkScreenWidth();
    }
  },
})
</script>
